import React from "react";
import { Link, NavLink } from "react-router-dom";
import SimpleReactValidator from "simple-react-validator";
import DOMPurify from "dompurify";
import {
	Row,
	Col,
	Container,
	Form,
	InputGroup,
	Button,
	Tab,
	Nav,
	ButtonToolbar,
	ToggleButton,
	ToggleButtonGroup,
	Image,
	OverlayTrigger,
	Tooltip,
	Badge,
	ThemeProvider,
	Card,
	Alert,
	Navbar,
} from "react-bootstrap";
import DataService from "../services/DataService";
import { alertError } from "../components/Alerts";
import { ONLINE_PAYMENT_MODES, currentURL } from "../helpers/globalConstants";
import StripeCheckoutButton from "./stripe/StripeCheckoutButton";
import { loadStripe } from "@stripe/stripe-js";
import StripeForm from "./common/StripeForm";
const ORDER_TYPES = ["DELIVERY", "TAKEOUT"];
const DELIVER_PREPAIR = ["deliver", "prepare"];
const DELIVER_ADDRESS_MESSAGE = [
	"Your order will be delivered to",
	"Please pickup your order from the following address.",
];
const Loading = 100;
const Payable = 1;
const Paid = 101;
const Expired = 3;
const Failed = 102;
const Verify = 105;

// const PAYWITHMONERIS = 103;

class DeliveryRequest extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			cart_details: {},
			status: props.payment_status,
			pay_status: false,
			tipStatus: true,
			tip: 0,
			tipPercentage: 0,
			profile: {},
			stripePromise: null,
			showStripeForm: false,
			stripeLoading: false,
			stripeError: null,
		};
		this.dataHandler = new DataService("restaurants");

		this.cartHandler = new DataService("carts");
		// if (props.payment_status == PAYWITHMONERIS) {
		// 	this.finishPayCollection("moneris");
		// }
		this.cartHandler.setCustomerID();
		// this.setStripePayment = this.setStripePayment.bind(this);
		this.setStripePaymentUsingIntent =
			this.setStripePaymentUsingIntent.bind(this);
		this.confirmStripePayment = this.confirmStripePayment.bind(this);
	}
	// getMonerisPage = () => {
	// 	let inputParams = {};

	// 	inputParams["name"] = this.props.key_name;
	// 	inputParams["cust_id"] = this.state.cust_id;
	// 	inputParams["order_id"] = this.state.cart_details.order_id;
	// 	inputParams["total"] = (
	// 		Number(this.state.cart_details["total_amount"]) + Number(this.state.tip)
	// 	).toFixed(2);
	// 	this.dataHandler.getMonerisTicket(inputParams).then((response) => {
	// 		if (response.data.status == "sucess") {
	// 			let currentData = response.data.data;
	// 			var ticket = currentData.ticket;
	// 			let inputForm = { ...this.state.cart_details };
	// 			inputForm["moneris_ticket"] = ticket;
	// 			inputForm["payment_mode"] = "PAY ONLINE";
	// 			inputForm["restaurant_name"] = this.props.key_name;
	// 			inputForm["payment_status"] = 0;
	// 			inputForm["total_amount"] = (
	// 				Number(inputForm["total_amount"]) + Number(this.state.tip)
	// 			).toFixed(2);
	// 			inputForm["order_id"] = this.state.cart_details.order_id;
	// 			this.dataHandler.setCartParams(this.props.key_name, inputForm);
	// 			this.setState(
	// 				{ cart_details: inputForm, pay_status: "moneris" },
	// 				() => {
	// 					window.loadMOneris(ticket);
	// 				}
	// 			);
	// 		} else {
	// 			alert("Moneris gateway is not responding");
	// 		}
	// 	});
	// };
	setTip = (val) => {
		val = Number(val);
		if (val < 0 || isNaN(val)) {
			val = 0;
		}
		this.setState({ tip: val }, () => {
			var status = val >= 0;
			this.setState({ tipStatus: status });
		});
	};

	verify = (id) => {
		new DataService("updateOrder")
			.createData({
				action_type: "update_order_payment_status",
				cart_id: id,
			})
			.then((response) => {
				if (
					response.data["status"] === "Sucess" ||
					response.data["status"] === "success"
				) {
					this.setState({
						pay_status: true,
						status: Paid,
					});
				} else {
					this.setState({
						pay_status: false,
						status: Failed,
					});
				}
			})
			.catch((err) => alert(err));
	};
	componentDidMount() {
		if (
			this.props.payment_status == Loading ||
			this.props.payment_status == Paid
		) {
			this.setCustomerParams();
		}
		if (this.props.payment_status == Verify) {
			this.verify(this.props.order_id);
		}
	}
	findTipPercentage = (ctip) => {
		var tipPercentage =
			(Number(this.state.tip) /
				Number(this.state.cart_details["total_amount"])) *
			100;
		if (tipPercentage == ctip) {
			return "border-none badge-success ml-1";
		} else {
			return "border-none badge-white myfont1 ml-1";
		}
	};
	// finishPayCollection = (mode = "stripe") => {
	// 	let inputParams = {};
	// 	if (mode == "moneris") {
	// 		// console.log(this.props.key_name);
	// 		// inputParams["restaurant_name"] = this.props.key_name;
	// 		// inputParams["order_id"] = this.props.order_id;
	// 		// inputParams["moneris"] = true;
	// 		// console.log("TestInputParams");
	// 		// console.log(inputParams);
	// 	} else {
	// 		inputParams = { ...this.state.cart_details };
	// 	}
	// 	this.cartHandler
	// 		.createPayCollection(inputParams)
	// 		.then((response) => {
	// 			if (response.data.status == "sucess") {
	// 				this.setState({
	// 					pay_status: true,
	// 					status: Paid,
	// 				});
	// 			} else {
	// 				this.setState({
	// 					pay_status: true,
	// 					status: Failed,
	// 				});
	// 			}
	// 		})
	// 		.catch((err) => alert(err));
	// };
	setCustomerParams = () => {
		let inputParams = {};
		inputParams["name"] = this.props.key_name;
		inputParams["order_id"] = this.props.order_id;
		this.dataHandler.getCartProfile(inputParams).then((response) => {
			if (response.data.status == "sucess") {
				let currentData = response.data.data;
				var cart = currentData.cart;
				var profile = currentData.profile;
				var newStatus = cart["pay_status"] == true ? Paid : Payable;
				if (newStatus == Paid) {
					this.verify(this.props.order_id);
					this.setState(
						{
							cart_details: cart,
							profile: profile,
							stripePromise: loadStripe(profile.stripe_key),
						},
						() => {
							var tipAmount = this.getPercentage(10);
							this.setState({ tip: tipAmount });
						}
					);
				} else {
					// if (this.state.status == 100) {
					// 	newStatus = cart["pay_status"] == false ? Payable : Expired;
					// }
					// if (this.state.status == 101) {
					// 	newStatus = cart["pay_status"] == true ? Paid : Expired;
					// }
					this.setState(
						{
							status: newStatus,
							cart_details: cart,
							pay_status: cart["pay_status"],
							profile: profile,
							stripePromise: loadStripe(profile.stripe_key),
						},
						() => {
							var tipAmount = this.getPercentage(10);
							this.setState({ tip: tipAmount });
						}
					);
				}
			} else {
				var profile = {};
				if (response.data.data) {
					profile = response.data.data.profile;
				}
				this.setState({
					status: Expired,
					profile: profile,
					stripePromise: loadStripe(profile.stripe_key),
				});
			}
		});
	};

	getPercentage = (perX) => {
		var tip = Number(this.state.cart_details["total_amount"] * perX) / 100;
		return tip;
	};

	// setMonerisPayment = (paymentToken) => {
	// 	this.cartHandler.getCartParams(this.state.key_name);
	// 	let inputForm = { ...this.state.cart_details };
	// 	inputForm["payment_mode"] = "PAY ONLINE";
	// 	inputForm["restaurant_name"] = this.state.profile.title;
	// 	inputForm["payment_status"] = 1;
	// 	inputForm["total_amount"] = (
	// 		Number(inputForm["total_amount"]) + Number(this.state.tip)
	// 	).toFixed(2);
	// 	inputForm["order_id"] = this.state.cart_details.order_id;
	// 	inputForm["payment_details_stripe"] = paymentToken;
	// 	this.setState({ cart_details: inputForm }, () => {
	// 		this.finishPayCollection();
	// 	});
	// };
	setStripeLoading = (value) => {
		this.setState({ stripeLoading: value });
	};
	setStripeError = (error, inputParams = null) => {
		this.setState({ stripeError: error });
		if (error) {
			this.setStripeLoading(false);
			if (inputParams) {
				this.emailIssue({
					restaurant_name: this.props.key_name,
					issue_type: 1,
					issue_message:
						"Process Failed for ipay stripe payment intent creation" +
						JSON.stringify(inputParams),
				});
			}
		}
	};

	setStripeFormShow = (value) => {
		this.setStripeError(null);
		this.setStripeLoading(false);
		this.setState({ showStripeForm: value });
	};

	emailIssue = (mail_params) => {
		new DataService("carts").emailClientIssue(mail_params);
	};

	// setStripePayment = (paymentToken) => {
	// 	let inputForm = { ...this.state.cart_details };
	// 	inputForm["payment_mode"] = "PAY ONLINE";
	// 	inputForm["restaurant_name"] = this.state.profile.title;
	// 	inputForm["payment_status"] = 1;
	// 	inputForm["total_amount"] = (
	// 		Number(inputForm["total_amount"]) + Number(this.state.tip)
	// 	).toFixed(2);
	// 	inputForm["order_id"] = this.state.cart_details.order_id;
	// 	inputForm["payment_details_stripe"] = paymentToken;
	// 	this.setState({ cart_details: inputForm }, () => {
	// 		this.finishPayCollection();
	// 	});
	// };

	confirmStripePayment = async (params) => {
		const { stripe, elements, clientSecret, id } = params;
		try {
			const result = await stripe.confirmPayment({
				//`Elements` instance that was used to create the Payment Element
				elements,
				clientSecret: clientSecret,
				confirmParams: {
					return_url:
						currentURL +
						this.state.key_name +
						"/delpayment/" +
						id +
						"/" +
						"verify",
				},
				redirect: "if_required",
			});
			if (result.error) {
				// console.log(result.error);
				// Show error to your customer (for example, payment details incomplete)
				this.setStripeError(
					result.error?.message ?? "An error occured on payment"
				);
				// console.log(result.error);
			} else {
				this.verify(this.props.order_id);

				// console.log("successssssssssssssss");
				// Your customer will be redirected to your `return_url`. For some payment
				// methods like iDEAL, your customer will be redirected to an intermediate
				// site first to authorize the payment, then redirected to the `return_url`.
			}
		} catch (er) {
			this.setStripeError("An error occured on payment confirmation");
			this.emailIssue({
				restaurant_name: this.props.key_name,
				issue_type: 1,
				issue_message:
					"Process Failed for ipay stripe payment confirmation" +
					JSON.stringify({ id, error: er }),
			});
		}
	};

	setStripePaymentUsingIntent = (params) => {
		// this.processCart();
		// if (this.state.stripeCreatedCart) {
		// 	this.confirmStripePayment({
		// 		...params,
		// 		clientSecret: this.state.stripeCreatedCart.clientSecret,
		// 		id: this.state.stripeCreatedCart.id,
		// 	});
		// } else {
		let inputParams = { ...this.state.cart_details, tip: this.state.tip };

		const createStripePaymentIntentHandler = new DataService(
			"createStripePaymentIntent"
		);

		createStripePaymentIntentHandler
			.createData(inputParams)
			.then((response) => {
				if (response.data["status"] === "success") {
					this.confirmStripePayment({
						...params,
						clientSecret:
							response.data?.data?.stripe_response?.client_secret,
						id: response.data?.data?.id,
					});
				} else {
					this.setStripeError("An error occured on payment", inputParams);
				}
			})
			.catch((err) => {
				this.setStripeError("An error occured on payment", inputParams);
			});

		// let customerParams = JSON.parse(inputParams.customer);
		// var pay_message = "payment confirmation pending order";

		// if (customerParams) {
		// 	customerParams["payment_status"] = 0;
		// 	customerParams["payment_mode"] = "PAY ONLINE";
		// 	customerParams["payment_details_stripe"] = true;

		// 	customerParams["payment_details"] = 0;
		// 	inputParams.customer = JSON.stringify(customerParams);

		// 	new DataService("createStripePaymentIntent")
		// 		.createCart(inputParams)
		// 		.then((response) => {
		// 			if (response.data["status"] === "Sucess") {
		// 				// this.setState({
		// 				// 	stripeCreatedCart: {
		// 				// 		id: response.data?.data?.id,
		// 				// 		clientSecret:
		// 				// 			response.data?.data?.stripe_response
		// 				// 				?.client_secret,
		// 				// 	},
		// 				// });
		// 				this.confirmStripePayment({
		// 					...params,
		// 					clientSecret:
		// 						response.data?.data?.stripe_response?.client_secret,
		// 					id: response.data?.data?.id,
		// 				});
		// 			} else {
		// 				this.setStripeError("An error occured on order creation");
		// 				this.emailIssue({
		// 					restaurant_name: this.props.key_name,
		// 					issue_type: 1,
		// 					issue_message:
		// 						"Process Failed for" +
		// 						"cart creation" +
		// 						"Order details :" +
		// 						JSON.stringify(inputParams),
		// 				});
		// 			}
		// 		})
		// 		.catch((err) => {
		// 			this.setStripeError("An error occured on order creation");
		// 			this.emailIssue({
		// 				restaurant_name: this.props.key_name,
		// 				issue_type: 1,
		// 				issue_message:
		// 					"Process Failed for" +
		// 					"cart creation" +
		// 					"Order details :" +
		// 					JSON.stringify(inputParams),
		// 			});
		// 		});
		// }
		// }
	};
	renderStatusMessage = (
		message,
		linkText = "",
		linkHref = "",
		isError = false
	) => (
		<div className="d-flex justify-content-center align-items-center min-vh-100">
			<div
				className={`card border-0 ${isError ? "bg-danger" : "bg-success"}`}
				style={{
					maxWidth: "400px",
					width: "90%",
					borderRadius: "12px",
					boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
				}}
			>
				<div className="card-body p-4 text-center">
					<p className="card-text text-white mb-3 fs-5">{message}</p>
					{linkText && (
						<a
							href={linkHref}
							className="btn btn-light px-4"
							data-testid="status-link"
						>
							{linkText}
						</a>
					)}
				</div>
			</div>
		</div>
	);

	renderLoader = () => (
		<div className="d-flex justify-content-center align-items-center min-vh-100">
			<div className="spinner-border text-primary" role="status">
				{/* <span className="visually-hidden">Loading...</span> */}
			</div>
		</div>
	);

	render() {
		const { pay_status, status, profile, cart_details, tip, tipStatus } =
			this.state;
		const totalAmount = (
			Number(cart_details["total_amount"]) + Number(tip)
		).toFixed(2);

		const renderPaymentSection = () => (
			<div
				className="min-vh-100"
				style={{
					backgroundImage: `radial-gradient(#00000006 2px, transparent 2px)`,
					backgroundSize: "24px 24px",
					backgroundColor: "#fafafa",
				}}
				data-testid="payment-section"
			>
				<div className="container py-3">
					<div className="mx-auto" style={{ maxWidth: "768px" }}>
						{/* Header attached to form */}
						<div className="bg-white rounded-top py-2 px-3 shadow-sm border-bottom">
							<div className="d-flex flex-column flex-md-row align-items-center justify-content-between gap-2 ">
								{/* Logo Badge - Fixed circular shape */}
								<div style={{ flex: "0 0 40px" }}>
									{typeof (profile.logo != "undefined") && (
										<div
											className="bg-light rounded-circle d-flex align-items-center justify-content-center"
											style={{
												width: "40px",
												height: "40px",
											}}
										>
											<Image
												fluid
												data-testid="restaurant-logo"
												alt="Restaurant Logo"
												src={`${window.image_path}${profile.logo}`}
												style={{
													width: "28px",
													height: "28px",
													objectFit: "contain",
												}}
											/>
										</div>
									)}
								</div>

								{/* Center Content */}
								<div className="flex-grow-1 text-center px-2">
									<h5 className="text-dark mb-1 fw-semibold fs-6">
										{profile.restaurant_name}
									</h5>
									<div className="small text-muted">
										is requesting online payment and is secured
										by stripe
									</div>
								</div>

								{/* Timer Badge - Fixed circular shape */}
								{/* <div style={{ flex: "0 0 80px" }}> */}
								<div
									className="small"
									style={{
										color: "#dc3545",
										fontSize: "10px",
									}}
								>
									<i className="bi bi-clock-fill"></i>
									<span className="ms-1">Expires in 20min</span>
								</div>
								{/* </div> */}
							</div>
						</div>

						{/* Form Container */}
						{this.state.showStripeForm ? (
							<div className="bg-white rounded-bottom shadow-sm py-3 px-0">
								<div className="offer-dedicated-body-left">
									<StripeForm
										stripePromise={this.state.stripePromise}
										price={parseInt(
											totalAmount.replace(".", ""),
											10
										)}
										rawPrice={totalAmount}
										setStripePaymentUsingIntent={
											this.setStripePaymentUsingIntent
										}
										setStripeLoading={this.setStripeLoading}
										setStripeFormShow={this.setStripeFormShow}
										stripeLoading={this.state.stripeLoading}
										stripeError={this.state.stripeError}
										setStripeError={this.setStripeError}
										businessName={profile.restaurant_name}
										currency={
											profile.currency
												? profile.currency
												: "CAD"
										}
										customerDetails={{
											email: "",
											first_name: cart_details["first_name"],
										}}
									/>
								</div>
							</div>
						) : (
							<div className="bg-white rounded-bottom shadow-sm p-3">
								<Form className="small">
									<Form.Group className="mb-2">
										<Form.Label className="fw-medium fs-5">
											Name
										</Form.Label>
										<Form.Control
											size="sm"
											type="text"
											value={cart_details["first_name"]}
											disabled
										/>
									</Form.Group>

									<Form.Group className="mb-2">
										<Form.Label className="fw-medium fs-5">
											Address
										</Form.Label>
										<Form.Control
											size="sm"
											type="text"
											value={cart_details["address"]}
											disabled
										/>
									</Form.Group>

									<Form.Group className="mb-2">
										<Form.Label className="fw-medium fs-5">
											Payable Amount
										</Form.Label>
										<Form.Control
											size="sm"
											type="text"
											value={cart_details["total_amount"]}
											disabled
										/>
									</Form.Group>

									<Form.Group className="mb-2">
										<Form.Label className="fw-medium fs-5">
											Tip your Driver
										</Form.Label>
										<Form.Control
											name="tip"
											size="sm"
											type="text"
											value={tip.toFixed(2)}
											onChange={(e) => {
												if (e) {
													this.setTip(e.target.value);
												}
											}}
										/>
										<div
											className={
												!tip || tip == 0
													? "d-flex gap-2 my-2 justify-content-between w-100"
													: "d-flex gap-2 my-2 justify-content-end"
											}
										>
											{(!tip || tip == 0) && (
												<div className="text-start">
													<Badge
														variant="primary"
														className="py-1 px-2"
													>
														100% of the tip goes to the
														driver
													</Badge>
												</div>
											)}
											<div>
												{[0, 5, 10, 15].map(
													(percentage) => (
														<button
															type="button"
															key={percentage}
															className={`${this.findTipPercentage(
																percentage
															)}`}
															style={{
																minWidth: "55px",
															}}
															onClick={() =>
																this.setTip(
																	this.getPercentage(
																		percentage
																	)
																)
															}
														>
															{percentage === 0
																? "No Tip"
																: `${percentage}%`}
														</button>
													)
												)}
											</div>
										</div>
									</Form.Group>

									<Form.Group className="mb-3">
										<Form.Label className="fw-bold fs-5">
											Total Amount To Be Paid
										</Form.Label>
										<Form.Control
											size="sm"
											type="text"
											value={totalAmount}
											disabled
										/>
									</Form.Group>
								</Form>
								{profile.online_payment_mode ==
									ONLINE_PAYMENT_MODES["STRIPE"] && (
									<div className="text-center pt-2">
										<button
											className="btn w-100 py-2 text-white fw-medium"
											style={{
												background: "rgb(38,43,50)",
												transition: "all 0.2s ease",
												boxShadow:
													"0 2px 4px rgba(0,0,0,0.1)",
											}}
											onMouseOver={(e) =>
												(e.currentTarget.style.background =
													"rgb(48,53,60)")
											}
											onMouseOut={(e) =>
												(e.currentTarget.style.background =
													"rgb(38,43,50)")
											}
											onClick={() =>
												this.setStripeFormShow(true)
											}
											disabled={!tipStatus}
										>
											Pay Online
										</button>
									</div>
								)}
							</div>
						)}
					</div>
				</div>
			</div>
		);

		// const renderStatusMessage = (message, linkText = "", linkHref = "") => (
		// 	<div className="text-center" data-testid="status-message">
		// 		<p>{message}</p>
		// 		{linkText && (
		// 			<a
		// 				href={linkHref}
		// 				className="btn btn-link"
		// 				data-testid="status-link"
		// 			>
		// 				{linkText}
		// 			</a>
		// 		)}
		// 	</div>
		// );

		if (pay_status == false && status === Payable) {
			return renderPaymentSection();
		} else if (pay_status == true && status === Paid) {
			return this.renderStatusMessage(
				`Your Payment has been successfully processed. ${profile.restaurant_name} would like to thank you for the payment. You can also place an online order with us.`,
				"Go Home",
				`/${this.props.key_name}`,
				false
			);
		} else if (status === Failed) {
			return this.renderStatusMessage(
				"Sorry! Transaction failed.",
				"",
				"",
				true
			);
		} else if (status === Expired) {
			return this.renderStatusMessage(
				"Expired Link.",
				"Go Home",
				`/${profile.title}`,
				true
			);
		} else {
			return this.renderLoader();
		}
	}
}

export default DeliveryRequest;
