import React, { Component } from "react";
import { Container, Nav, Row, Col } from "react-bootstrap";

class TermsAndConditionsLink extends Component {
	render() {
		const linkStyle = {
			// color: "#666",
			fontSize: "14px",
			padding: "5px 5px", // Reduced padding
			textDecoration: "none",
			transition: "color 0.3s ease",
			whiteSpace: "nowrap",
		};

		return (
			<Container fluid className="text-center mt-3 mb-3">
				<Row className="justify-content-center mb-2">
					<Col xs="auto">
						<span style={{ color: "#666", fontSize: "14px" }}>
							By placing this order you agree with:
						</span>
					</Col>
				</Row>
				<Row className="justify-content-center flex-nowrap align-items-center">
					<Col xs="auto">
						<Nav.Link
							href="https://iorders.ca/terms-of-use"
							target="_blank"
							style={linkStyle}
							onMouseOver={(e) => (e.target.style.color = "#333")}
							onMouseOut={(e) => (e.target.style.color = "#666")}
						>
							Terms of Service
						</Nav.Link>
					</Col>
					<span
						style={{
							borderLeft: "1px solid #666",
							height: "15px",
							margin: "0 5px",
						}}
					></span>
					<Col xs="auto">
						<Nav.Link
							href="https://iorders.ca/privacy-policy"
							target="_blank"
							style={linkStyle}
							onMouseOver={(e) => (e.target.style.color = "#333")}
							onMouseOut={(e) => (e.target.style.color = "#666")}
						>
							Privacy Policy
						</Nav.Link>
					</Col>
				</Row>
			</Container>
		);
	}
}

export default TermsAndConditionsLink;
